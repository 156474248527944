import React, { useState, useEffect } from 'react'; // Импортируем useState и useEffect
import './App.css'; // Импорт стилей
import Benefits from './Benefits'; // Импорт компонента Преимущества
import FAQSection from './FAQSection'; // Импорт компонента FAQ
import ContactForm from './ContactForm'; // Импорт компонента Форма обратной связи

function App() {
  // Состояния для управления раскрытием услуг
  const [showDetails, setShowDetails] = useState([false, false, false]);

  // Функция для переключения состояния
  const toggleDetails = (index) => {
    const newShowDetails = [...showDetails];
    newShowDetails[index] = !newShowDetails[index];
    setShowDetails(newShowDetails);
  };

  // Добавляем useEffect для управления видео
  useEffect(() => {
    const video = document.querySelector('.background-video');
    if (video) {
      // Отключение взаимодействия с видео на мобильных устройствах
      video.addEventListener('click', function (e) {
        e.preventDefault(); // Запретить нажатие, чтобы не открывался плеер
      });

      // Принудительное воспроизведение видео
      video.play().catch((error) => {
        console.error("Ошибка при воспроизведении видео:", error);
        video.load(); // Загружаем видео заново при ошибке
        video.play();
      });
    }
  }, []); // Пустой массив зависимостей, чтобы эффект выполнился только один раз при загрузке компонента

  return (
    <div className="App">
      <video autoPlay loop muted playsInline preload="auto" className="background-video">
        <source src="/background-video.mp4" type="video/mp4" />
        Ваш браузер не поддерживает HTML5 видео.
      </video>



      <header className="header">
        <img src="/logo.png" alt="Advector Logo" className="logo" />
        <h1 className="header-title">AdRealTime</h1>
        <p className="header-subtitle">Driving high-quality traffic for sustainable business growth</p>
      </header>

      <section className="about">
        <h2 className="section-title">About Us</h2>
        <p className="section-text">
          AdRealTime is a team of recognized media buying experts with 5 years of experience across various verticals. We develop personalized strategies that ensure a consistent flow of high-quality traffic and outstanding results for our partners. We know how to achieve success in the most competitive niches and help your projects grow.
        </p>
      </section>
      
      <section className="services">
        <h2 className="section-title">Our Services</h2>
        <div className="service-list">
          {/* Услуга 1 */}
          <div className="service-item">
            <div className="service-header" onClick={() => toggleDetails(0)}>
              <h3>Lead Generation</h3>
              <button className="toggle-button">{showDetails[0] ? '-' : '+'}</button>
            </div>
            {showDetails[0] && (
              <p>The full cycle of customer acquisition — from audience research and developing unique offers to launching ad campaigns and tracking conversions.</p>
            )}
          </div>
          {/* Услуга 2 */}
          <div className="service-item">
            <div className="service-header" onClick={() => toggleDetails(1)}>
              <h3>Creative Development and Branding</h3>
              <button className="toggle-button">{showDetails[1] ? '-' : '+'}</button>
            </div>
            {showDetails[1] && (
              <p>Creating compelling creatives and advertising materials that capture the audience's attention and enhance brand recognition. Working on visual identity and unique selling propositions.</p>
            )}
          </div>
          {/* Услуга 3 */}
          <div className="service-item">
            <div className="service-header" onClick={() => toggleDetails(2)}>
              <h3>Multichannel Strategy Management</h3>
              <button className="toggle-button">{showDetails[2] ? '-' : '+'}</button>
            </div>
            {showDetails[2] && (
              <p>Development and implementation of comprehensive strategies for various advertising platforms and channels (Facebook, Google, TikTok, etc.), optimizing budgets and targeting for maximum reach and engagement.</p>
            )}
          </div>
        </div>
      </section>

      {/* Секция Преимущества */}
      <Benefits />

      {/* Секция FAQ */}
      <FAQSection />

      <ContactForm />  {/* Форма обратной связи */}
      
      <footer className="footer">
        <p className="footer-text">© 2024 AdRealTime. All rights reserved.</p>
        <div className="footer-contact">
          <p>Contacts:</p>
          {/* WhatsApp and Telegram icons */}
          <a href="https://wa.me/123456789" className="contact-icon" target="_blank" rel="noopener noreferrer">
            <img src="/whatsapp-icon.png" alt="WhatsApp" />
          </a>
          <a href="https://t.me/yourusername" className="contact-icon" target="_blank" rel="noopener noreferrer">
            <img src="/telegram-icon.png" alt="Telegram" />
          </a>
          {/* Clickable link for sending an email */}
          <a href="mailto:info@adrealtime.pro" className="contact-email">
            info@adrealtime.pro
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
